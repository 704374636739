<template>
  <base-layout-main>
    <template v-slot:base-body>
      <main
        class="
          px-4
          py-8
          mx-auto
          sm:max-w-xl
          md:max-w-full
          lg:max-w-screen-xl
          md:px-22
          lg:px-8 lg:py-18
        "
      >
        <section
          class="
            px-4
            py-8
            mx-auto
            sm:max-w-xl
            md:max-w-full
            lg:max-w-screen-xl
            md:px-22
            lg:px-8 lg:py-18
          "
        >
          <div
            class="
              max-w-xl
              mb-10
              md:mx-auto
              sm:text-center
              lg:max-w-2xl
              md:mb-12
            "
          >
            <h1
              class="
                max-w-lg
                mb-6
                text-3xl text-purple-800
                font-bold
                leading-none
                tracking-tight
                sm:text-4xl
                md:mx-auto
              "
            >
              Our Apps
            </h1>
            <p class="text-base text-gray-700 md:text-lg">
              {{ caps["/apps"]["description"] }}
            </p>
          </div>
        </section>
        <section
          class="grid gap-8 row-gap-5 lg:grid-cols-4"
          style="cursor: pointer"
        >
          <a
            class="
              relative
              p-px
              overflow-hidden
              transition
              duration-300
              transform
              border
              rounded-lg
              shadow
              hover:scale-100
              group
              hover:shadow-xl
              active:scale-90
            "
            v-for="app in apps"
            :key="app.index"
            :href="app.path"
          >
            <div class="relative p-5 bg-white rounded-sm" :active="shadow - lg">
              <div class="flex flex-col mb-2 lg:items-center lg:flex-row">
                <component class="h-6 w-6" :is="app.icon" />
                <h6 class="font-semibold leading-5 text-purple-700">
                  &nbsp; {{ app.name }}
                </h6>
              </div>
              <p class="mb-2 text-md text-gray-600">
                {{ app.desc }}
              </p>
            </div>
          </a>
        </section>
      </main>
    </template>
  </base-layout-main>
</template>

<script>
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseLayoutMain from "@/layouts/BaseLayoutMain.vue";

import {
  ClockIcon,
  BookOpenIcon,
  ClipboardListIcon,
  CollectionIcon,
  XIcon,
} from "@heroicons/vue/outline";

export default defineComponent({
  name: "Apps",
  components: {
    BaseLayoutMain,
    ClockIcon,
    BookOpenIcon,
    ClipboardListIcon,
    CollectionIcon,
    XIcon,
  },
  setup() {
    const store = useStore();
    const caps = store.state.Captions.captions;
    const apps = [
      {
        name: "Timer",
        desc: caps["/apps"]["timer"],
        component: "AppTimer",
        icon: "ClockIcon",
        path: "/apps/timer",
      },
      {
        name: "Ah Counter",
        desc: caps["/apps"]["ah_counter"],
        component: "AppCounter",
        icon: "ClipboardListIcon",
        path: "/apps/ah-counter",
      },
      {
        name: "Grammarian",
        desc: caps["/apps"]["grammarian"],
        component: "AppRandomBook",
        icon: "BookOpenIcon",
        path: "/apps/grammarian",
      },
      {
        name: "Table Topics",
        desc: caps["/apps"]["table_topics"],
        component: "AppRandomBook",
        icon: "CollectionIcon",
        path: "/apps/table-topics",
      },
    ];

    return {
      caps,
      apps,
    };
  },
});
</script>

<style scoped>
main {
  font-family: "Baloo 2", cursive;
}
</style>